import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleButton", "icon"];

  connect() {
    console.log("Visibility controller connected");  // Debugging log
  }

  async toggle() {
    const button = this.toggleButtonTarget;
    const url = button.dataset.url;
    console.log("Toggling visibility...");  // Debugging log

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Response received:", data);  // Debugging log
      } else {
        console.error("Failed to toggle visibility.");
      }
    } catch (error) {
      console.error("Error toggling visibility:", error);
    }
  }

}
